
import {Component, Mixins, Prop} from 'vue-property-decorator';
import DynamicForm from '@/components/Forms/DynamicForm.vue';
import DynamicSection from '@/components/Forms/DynamicSection.vue';
import FormMixin from '@/components/Mixin/FormMixin';
import store from "@/store";
import OrderInfo from "@/components/Base/Parts/OrderInfo.vue";

@Component({
  components: {DynamicSection, OrderInfo}
})
// eslint-disable-next-line
// @ts-ignore: Unreachable code error
export default class DynamicFormProductLine extends Mixins(FormMixin, DynamicForm) {
  @Prop(String) sectionLabel;
  @Prop({required: true}) parentFormData?: any = undefined;
  @Prop(Object) lineSection;

  addDataToSaveToModel(dataToSave) {
    dataToSave.record = this.parentFormData.data.ID;
    dataToSave.module = this.parentFormData.module;
    return dataToSave;
  }

  async saveToModel(dataToSave, ID = null) {
    dataToSave = this.addDataToSaveToModel(dataToSave);
    // buffered after save main form
    if (this.bufferSave && !ID) {
      this.$emit("saveDataToFormBuffer", dataToSave);
      if (!this.formData.noCloseModalForm) {
        this.$emit("receiveResetShowForm");
      }
      this.formData.noCloseModalForm = false;
      return true;
    }
    // end buffer

    if (this.formData._saving) {
      return;
    }

    if (!dataToSave.ID) {
      this.formData._saving = true;
    }

    if (!this.parentFormData.data.ID && !ID) {
      // save main form before
      this.parentFormData.callbackAfterSave = (ID) => { this.saveToModel(dataToSave, ID) };
      this.$emit('saveTheForm', true);
    } else {
      // eslint-disable-next-line
      // @ts-ignore: Unreachable code error
      this.model.save(dataToSave, (ID) => {

        // for save and stay
        this.savedID = ID;
        if (!this.formData.autoSave) {
          this.initTheForm();
        }

        if (this.formData.callbackAlert) {
          store.state.alertMessage = dataToSave.ID ? "update" : "add";
        }

        if (this.callbackAfterSave) {
          this.callbackAfterSave(ID);
        }
        if (this.formData.callbackAfterSave) {
          (this.formData as any).callbackAfterSave(ID);
        }

        if (!this.formData.autoSave && !this.formData.noCloseModalForm) {
            this.$emit("receiveResetShowForm");
        }
        this.formData.noCloseModalForm = false;
        this.formData.autoSave = false;

        if (!dataToSave.ID) {
          const tt = setInterval(() => {
            if (this.formData.data.ID) {
              clearInterval(tt);
              this.formData._saving = false;
              
            }
          }, 500);
        }

      }).catch(() => {
        store.state.alertMessage = "error";
      });
    }
  }

  get bufferSave() {
    return this.$store.state.PORTAL && !this.parentFormData.data.ID;
  }

  formSectionsLocal() {
    if (this.dynSections) {
      return false;
    }
    const result: any = [];
    const sec = {
      id: 'general',
      name: 'general',
      order: 0,
      fields: {
        first: [] as any,
        second: [] as any,
        full: [] as any
      },
      type: 'fieldSection',
      label: this.sectionLabel
    };
    sec.fields.full = Object.values(this.dynFields).
        sort((a: any, b: any) => {
          return a.order - b.order
        });
    result.push(sec);
    return result;
  }

}
